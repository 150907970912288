import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class RolService {
  async getRolesAll() {
    const roles = await fetchWrapper.get(`${ruta}/rol`);
    return roles;
  }

  async sendRolNuevo(rol) {
    const nuevoRol = await fetchWrapper.post(`${ruta}/rol`, rol);
    return nuevoRol;
  }
  async deleteRol(rol) {
    const rolEliminado = await fetchWrapper.delete(
      `${ruta}/rol/` + rol.id
    );
    return rolEliminado;
  }
  async updatedRol(rol) {
    const rolActualizado = await fetchWrapper.put(
      `${ruta}/rol/` + rol.id,
      rol
    );
    return rolActualizado;
  }

  async asignarPermisosRol(datos) {
    const permisoasigando = await fetchWrapper.post(
      `${ruta}/asigar_permisos_a_rol`,
      datos
    );
    return permisoasigando;
  }
/*   async showRol(rol) {
    const rolShow = await fetchWrapper.get(`${ruta}/rol/` + rol);
    return rolShow;
  } */

  async getAllPermissions(){
    const allPermissions = await fetchWrapper.get(`${ruta}/permisos`);
    return allPermissions;
  }

  async obtenerPermisosPorAgrupadosModulos(){
    const allPermissions = await fetchWrapper.get(`${ruta}/obtener_permisos_agrupados_nombre`);
    return allPermissions;
  }
}