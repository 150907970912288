<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '550px' }"
    header="Médico Detalle"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-8">
          <label for="nombre"
            ><strong>NOMBRE COMPLETO: </strong>
            <span class="p-invalid">*</span></label
          >
          <InputText
            id="nombre_completo"
            v-model.trim="data_medico.nombre_completo"
            required="true"
            placeholder="Nombre Completo"
            autofocus
          />
          <small class="p-invalid" v-if="errors.nombre_completo">{{
            errors.nombre_completo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for="nit"><strong>NIT: </strong></label>
          <InputText
            id="nit"
            v-model.trim="data_medico.nit"
            placeholder="NIT"
            required="true"
          />
          <small class="p-invalid" v-if="errors.nit">{{ errors.nit[0] }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for="especialidad"><strong>ROL USUARIO: </strong></label>
          <Dropdown
            id="especialidad"
            v-model="data_medico.especialidad"
            :options="roles"
            optionLabel="name"
            optionValue="name"
            placeholder="Seleccione una especialidad..."
            :filter="true"
          />
          <small class="p-invalid" v-if="errors.especialidad">{{
            errors.especialidad[0]
          }}</small>
          <!--  <label for="especialidad"
            ><strong>ESPECIALIDAD: </strong>
            <span class="p-invalid">*</span></label
          >
          <InputText
            id="especialidad"
            placeholder="Especialidad"
            v-model.trim="data_medico.especialidad"
            required="true"
          />
          <small class="p-invalid" v-if="errors.especialidad">{{
            errors.especialidad[0]
          }}</small> -->
        </div>
        <div class="field col-12 md:col-4">
          <label for="matricula"><strong>MATR&Iacute;CULA: </strong></label>
          <InputText
            id="matricula"
            placeholder="Matrícula"
            v-model.trim="data_medico.matricula"
            required="true"
          />
          <small class="p-invalid" v-if="errors.matricula">{{
            errors.matricula[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for="telefono"><strong>TEL&Eacute;FONO: </strong></label>
          <InputText
            id="telefono"
            placeholder="Teléfono"
            v-model.trim="data_medico.telefono"
          />
          <small class="p-invalid" v-if="errors.telefono">{{
            errors.telefono[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-8">
          <label for="correo"
            ><strong>CORREO: <span class="p-invalid">*</span></strong></label
          >
          <InputText
            id="correo"
            placeholder="Correo"
            v-model.trim="data_medico.correo"
          />
          <small class="p-invalid" v-if="errors.correo">{{
            errors.correo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for="estado"
            ><strong>ESTADO: </strong><span class="p-invalid">*</span></label
          >
          <Dropdown
            id="estado"
            v-model="data_medico.estado"
            :disabled="!data_medico.id"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado..."
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
        :disabled="!enviado ? false : true"
      />
      <Button
        :label="data_medico.id ? 'ACTUALIZAR' : 'GUARDAR'"
        :icon="data_medico.id ? 'pi pi-pencil' : 'pi pi-save'"
        class="p-button-primary p-button-lg"
        @click="saveMedico"
        :disabled="!enviado ? false : true"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>

<script>
import MedicoService from "@/service/MedicoService";
import RolService from "@/service/RolService";
export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    medico: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  medicoService: null,
  rolService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_medico: this.medico,
      enviado: false,
      estados: [
        { label: "INACTIVO", value: 0 },
        { label: "ACTIVO", value: 1 },
      ],
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
      roles: [],
    };
  },
  mounted() {
    this.rolService.getRolesAll().then((data) => {
      this.roles = data;
      //QUE NO MUESTRE Administrador, ni ventas, ni recepcionista, secretaria
      this.roles = this.roles.filter(
        (rol) =>
          rol.name.toLowerCase() !== "administrador" &&
          rol.name.toLowerCase() !== "ventas" &&
          rol.name.toLowerCase() !== "recepcionista" &&
          rol.name.toLowerCase() !== "secretaria" &&
          rol.name.toLowerCase() !== "regente"
      );
    });
  },
  created() {
    this.medicoService = new MedicoService();
    this.rolService = new RolService();
  },
  methods: {
    ocultarDialog() {
      this.errors = {};
      this.mostrarModal = false;
      this.$emit("closeModal");
    },
    saveMedico() {
      this.errors = {};
      this.enviado = true;
      //editar medico
      if (this.medico.id) {
        let paqueteproducto_enviar = {
          ...this.data_medico,
          estado: this.data_medico.estado.value,
        };
        this.medicoService.updateMedico(paqueteproducto_enviar).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListado");
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      } else {
        //CREANDO UN NUEVO PROVEEDOR
        let tmp = this.data_medico;
        this.data_medico = {
          ...this.data_medico,
          estado: this.data_medico.estado.value,
        };
        this.medicoService.sendMedicoNew(this.data_medico).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
            this.data_medico = tmp;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 10000,
            });
            this.$emit("actualizarListado", data.medico);
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      }
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    medico(val) {
      this.data_medico = val;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
